/*
* Directory 1.0
* Copyright 2012, The Company Warehouse
* www.thecompanywarehouse.co.uk
* 07/12/2012
*/
/* Table of Contents
==================================================
	
	#Site Styles
	#Navigation
        #Shop
	#Page Styles
	#Page Specific Styles
	#Media Queries */
	/* #Fonts (in base.css)
================================================== */
/* #Site Styles
================================================== */
$dark-blue: #2d3291;
$dark-purple: #181d53;
$transparent-dark-blue: rgba(45,50,145,0.8);
$transparent-dark-purple: rgba(24,29,83,0.8);
@import 'header';
@import '_home.scss';
@import '_footer.scss';
@import '_internal.scss';
@font-face {
  font-family: 'Socialico';
  src: url("../fonts/Socialico.otf") format("opentype"), url("../fonts/Socialico.ttf") format("truetype"); }
.socialico {
  font-family: "Socialico"; }

@font-face {
  font-family: 'Socialico Plus';
  src: url("../fonts/Socialico Plus.otf") format("opentype"), url("../fonts/Socialico Plus.ttf") format("truetype"); }
.socialicoplus {
  font-family: "Socialico Plus"; }

html {
  overflow-y: scroll; }

body, a, p, span, div, ul, li, h1, h2, h3, h4, h5, h6 {
  font-family: "Lato",sans-serif; }

.img-div img {
  max-width: 100%;
  display: block;
  height: auto; }
  
.img-responsive {
	width:100%;
}

.dropdown-submenu {
  position: relative; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100% !important;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px; }

/*.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}*/
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px; }

.dropdown-submenu:hover > a:after {
  border-left-color: #fff; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px; }

  
.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
  text-align: left; }

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
  text-align: center; }

.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
  text-align: right; }

.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
  text-align: justify; }

@media (max-width: 767px) {
  .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
    text-align: inherit; }

  .text-left-xs {
    text-align: left; }

  .text-center-xs {
    text-align: center; }

  .text-right-xs {
    text-align: right; }

  .text-justify-xs {
    text-align: justify; } }
@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
    text-align: inherit; }

  .text-left-sm {
    text-align: left; }

  .text-center-sm {
    text-align: center; }

  .text-right-sm {
    text-align: right; }

  .text-justify-sm {
    text-align: justify; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
    text-align: inherit; }

  .text-left-md {
    text-align: left; }

  .text-center-md {
    text-align: center; }

  .text-right-md {
    text-align: right; }

  .text-justify-md {
    text-align: justify; } }
@media (min-width: 1200px) {
  .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
    text-align: inherit; }

  .text-left-lg {
    text-align: left; }

  .text-center-lg {
    text-align: center; }

  .text-right-lg {
    text-align: right; }

  .text-justify-lg {
    text-align: justify; } }
	
.img-background {
  background-size: cover;
  background-position: center; }
.dark-blue-bg{
  background-color: $dark-blue;
}
.dark-purple-bg{
  background-color: $dark-purple;
}
  
.rounded {
	border-radius:50%;
}
.carousel-fade{
  .carousel-control{
    z-index:2;
  }
  .carousel-inner{
    .item{
      transition-property:opacity;
      transition-duration:.5s;
    }
    .active.left,
    .active.right,
    .item{
      opacity:0;
    }
    .active,.next.left,.prev.right{
      opacity:1;
    }
    .active.left,.next,.prev{
      left:0;-webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0);
    }
  }
}