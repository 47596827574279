footer{
  padding: 20px 0;
  .footer-sm{
    margin:0 10px 0 0;
    display: inline-block;
    float: left;
    span{
      width: 25px;
      height: 25px;
      background-color: $dark-blue;
      color: #fff;
      line-height: 25px;
      text-align: center;
      border-radius: 100%;
      padding: 0;
      border: 0;
      margin: 0 10px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  span{
    margin-top: 3px;
    margin-right: 7px;
    border-right: 1px solid #000;
    padding-right: 10px;
    display: inline-block;
    a{
      color: inherit;
    }
    &:nth-child(n+4){
      border-right: 0;
    }
  }
  .copy-right{
    font-size: 12px;
    display: inline-block;
    float: right;
  }
  .footer-info-center{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media(max-width: 1200px){
  footer{
    .copy-right{
      display: block;
      float: none;
      margin-top: 15px;
      clear: both;
    }
  }
}
@media(max-width: 768px){
  footer{
    span{
      margin-left: 0;
      margin-right: 0;
      display: block;
      float: none;
      clear:both;
      text-align: center;
      border: 0!important;
    }
    .footer-sm{
      display: block;
      float: none;
      text-align: center;
      margin: 15px 0;
    }
    .footer-info-center{
      text-align: center;
    }
  }
}