/*
* Directory 1.0
* Copyright 2012, The Company Warehouse
* www.thecompanywarehouse.co.uk
* 07/12/2012
*/
/* Table of Contents
==================================================
	
	#Site Styles
	#Navigation
        #Shop
	#Page Styles
	#Page Specific Styles
	#Media Queries */
/* #Fonts (in base.css)
================================================== */
/* #Site Styles
================================================== */
header .logo-div {
  margin-top: 15px;
  margin-bottom: 25px; }

header .phone {
  margin-right: 20px; }

header .contact-item {
  display: inline-block;
  float: right;
  margin-top: 35px; }
  header .contact-item span {
    margin: 0 10px; }
  header .contact-item a {
    padding: 5px 30px;
    background-color: #2d3291;
    color: #fff; }
    header .contact-item a:hover {
      text-decoration: underline; }

header .search-wrapper {
  display: inline-block;
  float: none;
  clear: both;
  margin-top: 15px; }
  header .search-wrapper .input-group-addon {
    background-color: #181d53;
    padding: 4px 35px;
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: 14px; }

.navbar {
  background-color: #2d3291;
  min-height: 1px;
  margin-bottom: 0;
  border-radius: 0;
  border: 0; }
  .navbar .navbar-collapse {
    padding: 0; }
    .navbar .navbar-collapse .navbar-nav {
      float: none;
      text-align: center; }
      .navbar .navbar-collapse .navbar-nav li {
        display: inline-block;
        float: none; }
        .navbar .navbar-collapse .navbar-nav li a {
          background-color: transparent;
          color: #fff;
          padding: 0 108px;
          border-left: 1px solid #fff;
          margin: 10px 0;
          text-transform: uppercase; }
        .navbar .navbar-collapse .navbar-nav li:last-child a {
          border-right: 1px solid #fff; }
      .navbar .navbar-collapse .navbar-nav .active a {
        background-color: #181d53;
        border: none !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        font-weight: bold; }
      .navbar .navbar-collapse .navbar-nav .active + li a {
        border-left: 0 !important; }

@media (max-width: 1200px) {
  .navbar .navbar-collapse .navbar-nav li a {
    padding: 0 83px; } }

@media (max-width: 993px) {
  .navbar .navbar-collapse .navbar-nav li a {
    padding: 0 55px; } }

@media (max-width: 767px) {
  header .contact-item {
    display: block;
    clear: both;
    float: none;
    text-align: center;
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0; }
    header .contact-item span {
      display: block;
      float: none;
      clear: both; }
  header .logo-div {
    margin-bottom: 15px; }
  header .search-wrapper {
    margin-bottom: 10px; }
  .navbar .navbar-collapse {
    margin: 0; }
    .navbar .navbar-collapse .navbar-nav {
      margin-left: 0;
      margin-right: 0; }
      .navbar .navbar-collapse .navbar-nav li {
        display: block;
        float: none;
        width: 100%;
        text-align: center; }
        .navbar .navbar-collapse .navbar-nav li a {
          padding: 10px 0;
          border: 0 !important; }
  .navbar-toggle {
    background-color: #181d53;
    border: 0;
    border-radius: 0; }
    .navbar-toggle .icon-bar {
      background-color: #fff; } }

#home-section-1 .item {
  min-height: 400px; }
  #home-section-1 .item .home-section-1-content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100px;
    margin: auto; }
    #home-section-1 .item .home-section-1-content-wrapper h1 {
      margin: 30px 0;
      color: #181d53;
      font-weight: 900; }
    #home-section-1 .item .home-section-1-content-wrapper .trailing-background {
      width: calc(((100% - 1200px) / 2) + 15px);
      background-color: rgba(255, 255, 255, 0.5);
      min-height: 100px;
      display: inline-block;
      float: left; }
    #home-section-1 .item .home-section-1-content-wrapper .top-text {
      background-color: rgba(255, 255, 255, 0.5); }
  #home-section-1 .item .dark-blue-bg {
    margin-top: 40px; }
    #home-section-1 .item .dark-blue-bg h2 {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold; }
    #home-section-1 .item .dark-blue-bg .port-section-wrapper {
      background-image: linear-gradient(#42479c, #a0a3cd); }
      #home-section-1 .item .dark-blue-bg .port-section-wrapper .port-wrapper {
        display: block;
        overflow: hidden;
        border-bottom: 5px solid #2d3291; }
        #home-section-1 .item .dark-blue-bg .port-section-wrapper .port-wrapper span {
          color: #fff;
          padding: 5px 15px;
          text-transform: uppercase;
          font-weight: bold; }
        #home-section-1 .item .dark-blue-bg .port-section-wrapper .port-wrapper .pull-right {
          border-left: 1px solid #fff; }
      #home-section-1 .item .dark-blue-bg .port-section-wrapper .port-extra {
        background-color: #181d53;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        width: 100%;
        display: block;
        float: none; }

#home-section-2 {
  margin: 30px 0; }
  #home-section-2 p {
    margin: 0; }
  #home-section-2 .border-right {
    border-right: 2px solid #181d53;
    text-align: center; }
    #home-section-2 .border-right h2 {
      color: #181d53;
      font-weight: 900;
      font-size: 35px; }

#home-section-3 .service-title {
  min-height: 150px;
  position: relative;
  margin-bottom: 20px; }
  #home-section-3 .service-title h2 {
    margin: 0;
    padding: 20px 50px 20px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase; }

#home-section-3 .read-more {
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  margin-top: 15px; }

#home-section-3 .service-1 .service-title h2 {
  background-color: rgba(45, 50, 145, 0.8); }

#home-section-3 .service-1 .read-more {
  background-color: #2d3291; }

#home-section-3 .service-2 .service-title h2 {
  background-color: rgba(24, 29, 83, 0.8); }

#home-section-3 .service-2 .read-more {
  background-color: #181d53; }

#home-section-4 {
  background-color: #2d3291;
  margin-top: 30px;
  padding: 50px 0; }
  #home-section-4 .img-div img {
    width: 100%; }
  #home-section-4 h2 {
    color: #fff;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 20px; }
    #home-section-4 h2 span {
      font-size: 18px;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      color: #2d3291;
      background-color: #fff;
      float: left;
      display: inline-block;
      line-height: 30px;
      text-align: center;
      margin: 2px 10px 0 0; }
    #home-section-4 h2 a {
      font-size: 16px;
      color: #fff;
      text-decoration: underline;
      text-transform: none;
      display: inline-block;
      float: right;
      margin-top: 10px; }
  #home-section-4 .port-section-wrapper {
    background-image: linear-gradient(#42479c, #a0a3cd); }
    #home-section-4 .port-section-wrapper .port-wrapper {
      display: block;
      overflow: hidden;
      border-bottom: 5px solid #2d3291; }
      #home-section-4 .port-section-wrapper .port-wrapper span {
        color: #fff;
        padding: 5px 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px; }
      #home-section-4 .port-section-wrapper .port-wrapper .pull-right {
        border-left: 1px solid #fff; }
    #home-section-4 .port-section-wrapper .port-extra {
      background-color: #181d53;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      width: 100%;
      display: block;
      float: none; }

#home-section-5 h2 {
  text-transform: uppercase;
  font-weight: bold; }

#home-section-5 .container {
  background-color: rgba(24, 29, 83, 0.8);
  margin-top: 80px;
  margin-bottom: 80px;
  color: #fff; }
  #home-section-5 .container .border-right {
    border-right: 3px solid #fff;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px; }
    #home-section-5 .container .border-right h2 {
      margin-top: 0; }
  #home-section-5 .container .border-left {
    margin-top: 20px;
    border-left: 3px solid #fff;
    margin-bottom: 20px; }
  #home-section-5 .container .default-form .cms_textfield {
    overflow: hidden;
    margin: 15px 0; }
    #home-section-5 .container .default-form .cms_textfield:first-child {
      margin-top: 0px; }
    #home-section-5 .container .default-form .cms_textfield label {
      color: #fff;
      float: left;
      display: inline-block;
      max-width: 35%;
      text-align: right;
      width: 100%;
      text-transform: uppercase; }
    #home-section-5 .container .default-form .cms_textfield input, #home-section-5 .container .default-form .cms_textfield textarea {
      border: 0;
      border-radius: 0;
      display: inline-block;
      float: right;
      max-width: 60%;
      width: 100%; }
  #home-section-5 .container .default-form .submit input {
    border: 0;
    border-radius: 0;
    max-width: 60%;
    width: 100%;
    background-color: #2d3291;
    text-align: center;
    display: inline-block;
    float: right;
    text-transform: uppercase;
    font-weight: bold; }
  #home-section-5 .container .default-form .submit .form-message {
    display: inline-block;
    float: right;
    clear: both; }

@media (max-width: 1200px) {
  #home-section-1 .item .home-section-1-content-wrapper .trailing-background {
    width: calc(((100% - 1000px) / 2) + 15px); } }

@media (max-width: 993px) {
  #home-section-1 .item .home-section-1-content-wrapper .trailing-background {
    width: calc(((100% - 780px) / 2) + 15px); }
  #home-section-4 h2 a {
    float: none; } }

@media (max-width: 768px) {
  #home-section-1 .item .home-section-1-content-wrapper {
    top: initial;
    max-height: 100%; }
    #home-section-1 .item .home-section-1-content-wrapper .trailing-background {
      display: none; }
    #home-section-1 .item .home-section-1-content-wrapper h1 {
      font-size: 25px; }
  #home-section-3 .service-1 {
    margin-bottom: 20px; }
  #home-section-3 .service-title h2 {
    padding: 20px 0;
    text-align: center;
    right: 0; }
  #home-section-4 {
    padding: 30px 0; }
    #home-section-4 h2 {
      margin-bottom: 15px; }
      #home-section-4 h2 a {
        float: none;
        display: block;
        clear: both; }
    #home-section-4 .port-section-wrapper {
      margin-bottom: 20px; }
  #home-section-5 .container {
    padding-bottom: 20px; }
    #home-section-5 .container .border-right {
      border: 0; }
    #home-section-5 .container .default-form .cms_textfield:first-child {
      margin-top: 0; }
    #home-section-5 .container .default-form .cms_textfield label, #home-section-5 .container .default-form .cms_textfield input, #home-section-5 .container .default-form .cms_textfield textarea {
      text-align: left;
      max-width: 100%;
      float: none;
      clear: both; }
    #home-section-5 .container .default-form .submit input {
      max-width: 100%; } }

footer {
  padding: 20px 0; }
  footer .footer-sm {
    margin: 0 10px 0 0;
    display: inline-block;
    float: left; }
    footer .footer-sm span {
      width: 25px;
      height: 25px;
      background-color: #2d3291;
      color: #fff;
      line-height: 25px;
      text-align: center;
      border-radius: 100%;
      padding: 0;
      border: 0;
      margin: 0 10px; }
      footer .footer-sm span:first-child {
        margin-left: 0; }
  footer span {
    margin-top: 3px;
    margin-right: 7px;
    border-right: 1px solid #000;
    padding-right: 10px;
    display: inline-block; }
    footer span a {
      color: inherit; }
    footer span:nth-child(n+4) {
      border-right: 0; }
  footer .copy-right {
    font-size: 12px;
    display: inline-block;
    float: right; }
  footer .footer-info-center {
    margin-top: 20px;
    margin-bottom: 20px; }

@media (max-width: 1200px) {
  footer .copy-right {
    display: block;
    float: none;
    margin-top: 15px;
    clear: both; } }

@media (max-width: 768px) {
  footer span {
    margin-left: 0;
    margin-right: 0;
    display: block;
    float: none;
    clear: both;
    text-align: center;
    border: 0 !important; }
  footer .footer-sm {
    display: block;
    float: none;
    text-align: center;
    margin: 15px 0; }
  footer .footer-info-center {
    text-align: center; } }

#default-section-1 .container-fluid {
  min-height: 175px;
  position: relative; }
  #default-section-1 .container-fluid .home-section-1-content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: initial;
    bottom: 0;
    max-height: 100px; }
    #default-section-1 .container-fluid .home-section-1-content-wrapper h1 {
      margin: 30px 0;
      color: #181d53;
      font-weight: 900; }
    #default-section-1 .container-fluid .home-section-1-content-wrapper .trailing-background {
      width: calc(((100% - 1200px) / 2) + 15px);
      background-color: rgba(255, 255, 255, 0.5);
      min-height: 100px;
      display: inline-block;
      float: left; }
    #default-section-1 .container-fluid .home-section-1-content-wrapper .top-text {
      background-color: rgba(255, 255, 255, 0.5); }

#default-section-2 {
  margin-top: 30px; }
  #default-section-2 .img-background {
    position: relative;
    min-height: 200px; }
  #default-section-2 .dark-blue h2 {
    color: #fff;
    background-color: rgba(45, 50, 145, 0.8);
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 15px; }
  #default-section-2 .dark-blue .service-link a {
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #2d3291;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 15px 0;
    display: inline-block; }
    #default-section-2 .dark-blue .service-link a:hover {
      background-color: #2d3291;
      text-decoration: none; }
  #default-section-2 .dark-purple h2 {
    color: #fff;
    background-color: rgba(24, 29, 83, 0.8);
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 15px; }
  #default-section-2 .dark-purple .service-link a {
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #181d53;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 15px 0;
    display: inline-block; }
    #default-section-2 .dark-purple .service-link a:hover {
      background-color: #181d53;
      text-decoration: none; }
  #default-section-2 hr {
    border-color: #2d3291; }
  #default-section-2 .default-form .cms_textfield {
    overflow: hidden;
    margin: 15px 0; }
    #default-section-2 .default-form .cms_textfield:first-child {
      margin-top: 0px; }
    #default-section-2 .default-form .cms_textfield label {
      color: #000;
      float: left;
      display: inline-block;
      max-width: 35%;
      text-align: right;
      width: 100%;
      text-transform: uppercase; }
    #default-section-2 .default-form .cms_textfield input, #default-section-2 .default-form .cms_textfield textarea {
      border-radius: 0;
      display: inline-block;
      float: right;
      max-width: 60%;
      width: 100%; }
  #default-section-2 .default-form .submit input {
    border: 0;
    border-radius: 0;
    max-width: 60%;
    width: 100%;
    background-color: #2d3291;
    text-align: center;
    display: inline-block;
    float: right;
    text-transform: uppercase;
    font-weight: bold; }
  #default-section-2 .default-form .submit .form-message {
    dispay: inline-block;
    float: right;
    clear: both; }

#default-section-3 .service-title {
  min-height: 150px;
  position: relative;
  margin-bottom: 20px; }
  #default-section-3 .service-title h2 {
    margin: 0;
    padding: 20px 50px 20px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase; }

#default-section-3 .read-more {
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  padding: 10px 0; }

#default-section-3 .service-1 .service-title h2 {
  background-color: rgba(45, 50, 145, 0.8); }

#default-section-3 .service-1 .read-more {
  background-color: #2d3291; }

#default-section-3 .service-2 .service-title h2 {
  background-color: rgba(24, 29, 83, 0.8); }

#default-section-3 .service-2 .read-more {
  background-color: #181d53; }

#default-section-4 {
  background-color: #2d3291;
  margin-top: 30px;
  padding: 50px 0; }
  #default-section-4 h2 {
    color: #fff;
    text-transform: uppercase;
    margin-top: 0; }
    #default-section-4 h2 span {
      font-size: 18px;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      color: #2d3291;
      background-color: #fff;
      float: left;
      display: inline-block;
      line-height: 30px;
      text-align: center;
      margin: 2px 10px 0 0; }
    #default-section-4 h2 a {
      font-size: 16px;
      color: #fff;
      text-decoration: underline;
      text-transform: none;
      display: inline-block;
      float: right;
      margin-top: 10px; }
  #default-section-4 .port-section-wrapper {
    background-image: linear-gradient(#42479c, #a0a3cd); }
    #default-section-4 .port-section-wrapper .port-wrapper {
      display: block;
      overflow: hidden;
      border-bottom: 5px solid #2d3291; }
      #default-section-4 .port-section-wrapper .port-wrapper span {
        color: #fff;
        padding: 5px 15px;
        text-transform: uppercase;
        font-weight: bold; }
      #default-section-4 .port-section-wrapper .port-wrapper .pull-right {
        border-left: 1px solid #fff; }
    #default-section-4 .port-section-wrapper .port-extra {
      background-color: #181d53;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      width: 100%;
      display: block;
      float: none; }

#default-section-5 .container {
  background-color: rgba(24, 29, 83, 0.8);
  margin-top: 80px;
  margin-bottom: 80px; }
  #default-section-5 .container .border-right {
    border-right: 3px solid #fff;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px; }
    #default-section-5 .container .border-right h2 {
      margin-top: 0; }
  #default-section-5 .container .default-form .cms_textfield {
    overflow: hidden;
    margin: 15px 0; }
    #default-section-5 .container .default-form .cms_textfield:first-child {
      margin-top: 25px; }
    #default-section-5 .container .default-form .cms_textfield label {
      color: #fff;
      float: left;
      display: inline-block;
      max-width: 20%;
      text-align: right;
      width: 100%; }
    #default-section-5 .container .default-form .cms_textfield input, #default-section-5 .container .default-form .cms_textfield textarea {
      border: 0;
      border-radius: 0;
      display: inline-block;
      float: right;
      max-width: 75%;
      width: 100%; }
  #default-section-5 .container .default-form .submit input {
    border: 0;
    border-radius: 0;
    max-width: 75%;
    width: 100%;
    background-color: #2d3291;
    text-align: center;
    display: inline-block;
    float: right; }

@media (max-width: 1200px) {
  #default-section-1 .container-fluid .home-section-1-content-wrapper .trailing-background {
    width: calc(((100% - 1000px) / 2) + 15px); } }

@media (max-width: 993px) {
  #default-section-1 .container-fluid .home-section-1-content-wrapper .trailing-background {
    width: calc(((100% - 780px) / 2) + 15px); } }

@media (max-width: 768px) {
  #default-section-1 .container-fluid .home-section-1-content-wrapper {
    top: initial;
    max-height: 100%; }
    #default-section-1 .container-fluid .home-section-1-content-wrapper .trailing-background {
      display: none; }
    #default-section-1 .container-fluid .home-section-1-content-wrapper h1 {
      font-size: 25px; }
  #default-section-2 {
    margin-bottom: -15px; }
    #default-section-2 .service-title {
      margin-bottom: 15px; }
    #default-section-2 .default-form .cms_textfield:first-child {
      margin-top: 0; }
    #default-section-2 .default-form .cms_textfield label, #default-section-2 .default-form .cms_textfield input, #default-section-2 .default-form .cms_textfield textarea {
      text-align: left;
      max-width: 100%;
      float: none;
      clear: both; }
    #default-section-2 .default-form .submit input {
      max-width: 100%; } }

@font-face {
  font-family: 'Socialico';
  src: url("../fonts/Socialico.otf") format("opentype"), url("../fonts/Socialico.ttf") format("truetype"); }

.socialico {
  font-family: "Socialico"; }

@font-face {
  font-family: 'Socialico Plus';
  src: url("../fonts/Socialico Plus.otf") format("opentype"), url("../fonts/Socialico Plus.ttf") format("truetype"); }

.socialicoplus {
  font-family: "Socialico Plus"; }

html {
  overflow-y: scroll; }

body, a, p, span, div, ul, li, h1, h2, h3, h4, h5, h6 {
  font-family: "Lato",sans-serif; }

.img-div img {
  max-width: 100%;
  display: block;
  height: auto; }

.img-responsive {
  width: 100%; }

.dropdown-submenu {
  position: relative; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100% !important;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px; }

/*.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}*/
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px; }

.dropdown-submenu:hover > a:after {
  border-left-color: #fff; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px; }

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
  text-align: left; }

.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
  text-align: center; }

.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
  text-align: right; }

.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
  text-align: justify; }

@media (max-width: 767px) {
  .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
    text-align: inherit; }
  .text-left-xs {
    text-align: left; }
  .text-center-xs {
    text-align: center; }
  .text-right-xs {
    text-align: right; }
  .text-justify-xs {
    text-align: justify; } }

@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
    text-align: inherit; }
  .text-left-sm {
    text-align: left; }
  .text-center-sm {
    text-align: center; }
  .text-right-sm {
    text-align: right; }
  .text-justify-sm {
    text-align: justify; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
    text-align: inherit; }
  .text-left-md {
    text-align: left; }
  .text-center-md {
    text-align: center; }
  .text-right-md {
    text-align: right; }
  .text-justify-md {
    text-align: justify; } }

@media (min-width: 1200px) {
  .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
    text-align: inherit; }
  .text-left-lg {
    text-align: left; }
  .text-center-lg {
    text-align: center; }
  .text-right-lg {
    text-align: right; }
  .text-justify-lg {
    text-align: justify; } }

.img-background {
  background-size: cover;
  background-position: center; }

.dark-blue-bg {
  background-color: #2d3291; }

.dark-purple-bg {
  background-color: #181d53; }

.rounded {
  border-radius: 50%; }

.carousel-fade .carousel-control {
  z-index: 2; }

.carousel-fade .carousel-inner .item {
  transition-property: opacity;
  transition-duration: .5s; }

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right,
.carousel-fade .carousel-inner .item {
  opacity: 0; }

.carousel-fade .carousel-inner .active, .carousel-fade .carousel-inner .next.left, .carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .active.left, .carousel-fade .carousel-inner .next, .carousel-fade .carousel-inner .prev {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*# sourceMappingURL=style.css.map */
