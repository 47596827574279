#default-section-1{
  .container-fluid {
    min-height: 175px;
    position:  relative;
    .home-section-1-content-wrapper {
      position:absolute;
      left: 0;
      right: 0;
      top: initial;
      bottom: 0;
      max-height: 100px;
      h1{
        margin:30px 0;
        color: $dark-purple;
        font-weight: 900;
      }
      .trailing-background {
        width: calc(((100% - 1200px) / 2) + 15px);
        background-color: rgba(255, 255, 255, .5);
        min-height: 100px;
        display: inline-block;
        float: left;
      }
      .top-text{
        background-color: rgba(255,255,255,0.5);
      }
    }
  }
}
#default-section-2{
  margin-top: 30px;
 .img-background {
   position: relative;
   min-height: 200px;
 }
  .dark-blue{
    h2 {
      color: #fff;
      background-color: $transparent-dark-blue;
      margin-bottom: 20px;
      font-weight: bold;
      margin-top: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 15px;
    }
    .service-link{
      a{
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: $dark-blue;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 15px 0;
        display: inline-block;
        &:hover{
          background-color: $dark-blue;
          text-decoration: none;
        }
      }
    }
  }
  .dark-purple{
    h2 {
      color: #fff;
      background-color: $transparent-dark-purple;
      margin-bottom: 20px;
      font-weight: bold;
      margin-top: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 15px;
    }
    .service-link{
      a{
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: $dark-purple;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 15px 0;
        display: inline-block;
        &:hover{
          background-color: $dark-purple;
          text-decoration: none;
        }
      }
    }
  }
  hr{
    border-color: $dark-blue;
  }
  .default-form{
    .cms_textfield{
      overflow: hidden;
      margin: 15px 0;
      &:first-child{
        margin-top: 0px;
      }
      label{
        color:#000;
        float: left;
        display: inline-block;
        max-width: 35%;
        text-align: right;
        width: 100%;
        text-transform: uppercase;
      }
      input, textarea{
        border-radius: 0;
        display: inline-block;
        float: right;
        max-width: 60%;
        width: 100%;
      }
    }
    .submit{
      input{
        border: 0;
        border-radius: 0;
        max-width: 60%;
        width: 100%;
        background-color: $dark-blue;
        text-align: center;
        display: inline-block;
        float: right;
        text-transform: uppercase;
        font-weight: bold;
      }
      .form-message{
        dispay: inline-block;
        float: right;
        clear: both;
      }
    }
  }
}
#default-section-3{
  .service-title{
    min-height: 150px;
    position: relative;
    margin-bottom: 20px;
    h2{
      margin: 0;
      padding: 20px 50px 20px 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .read-more{
    width: 100%;
    display: block;
    text-align: center;
    color: #fff;
    padding: 10px 0;
  }
  .service-1{
    .service-title{
      h2{
        background-color: $transparent-dark-blue;
      }
    }
    .read-more{
      background-color: $dark-blue;
    }
  }
  .service-2{
    .service-title{
      h2{
        background-color: $transparent-dark-purple;
      }
    }
    .read-more{
      background-color: $dark-purple;
    }
  }
}
#default-section-4{
  background-color: $dark-blue;
  margin-top: 30px;
  padding:50px 0;
  h2{
    color: #fff;
    text-transform: uppercase;
    margin-top: 0;
    span{
      font-size:18px;
      height:30px;
      width: 30px;
      border-radius: 100%;
      color: $dark-blue;
      background-color: #fff;
      float: left;
      display: inline-block;
      line-height: 30px;
      text-align: center;
      margin: 2px 10px 0 0;
    }
    a{
      font-size: 16px;
      color: #fff;
      text-decoration: underline;
      text-transform: none;
      display: inline-block;
      float: right;
      margin-top: 10px;
    }
  }
  .port-section-wrapper{
    background-image: linear-gradient(#42479c,#a0a3cd);
    .port-wrapper{
      display: block;
      overflow: hidden;
      border-bottom: 5px solid $dark-blue;
      span{
        color: #fff;
        padding: 5px 15px;
        text-transform: uppercase;
        font-weight: bold;
      }
      .pull-right{
        border-left: 1px solid #fff;
      }
    }
    .port-extra{
      background-color: $dark-purple;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      width: 100%;
      display: block;
      float: none;
    }
  }
}
#default-section-5{
  .container{
    background-color: $transparent-dark-purple;
    margin-top: 80px;
    margin-bottom: 80px;
    .border-right{
      border-right: 3px solid #fff;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      h2{
        margin-top: 0;
      }
    }
    .default-form{
      .cms_textfield{
        overflow: hidden;
        margin: 15px 0;
        &:first-child{
          margin-top: 25px;
        }
        label{
          color:#fff;
          float: left;
          display: inline-block;
          max-width: 20%;
          text-align: right;
          width: 100%;
        }
        input, textarea{
          border: 0;
          border-radius: 0;
          display: inline-block;
          float: right;
          max-width: 75%;
          width: 100%;
        }
      }
      .submit{
        input{
          border: 0;
          border-radius: 0;
          max-width: 75%;
          width: 100%;
          background-color: $dark-blue;
          text-align: center;
          display: inline-block;
          float: right;
        }
      }
    }
  }
}
@media(max-width: 1200px){
  #default-section-1{
    .container-fluid{
      .home-section-1-content-wrapper{
        .trailing-background{
          width: calc(((100% - 1000px) / 2) + 15px);
        }
      }
    }
  }
}
@media(max-width: 993px){
  #default-section-1{
    .container-fluid{
      .home-section-1-content-wrapper{
        .trailing-background{
          width: calc(((100% - 780px) / 2) + 15px);
        }
      }
    }
  }
}
@media(max-width: 768px) {
  #default-section-1 {
    .container-fluid {
      .home-section-1-content-wrapper {
        top: initial;
        max-height: 100%;
        .trailing-background {
          display: none;
        }
        h1 {
          font-size: 25px;
        }
      }
    }
  }
  #default-section-2{
    margin-bottom: -15px;
    .service-title{
      margin-bottom: 15px;
    }
    .default-form{
      .cms_textfield{
        &:first-child{
          margin-top: 0;
        }
        label,input,textarea{
          text-align: left;
          max-width: 100%;
          float: none;
          clear: both;
        }
      }
      .submit{
        input{
          max-width: 100%;
        }
      }
    }
  }
}