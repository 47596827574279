header{
  .logo-div{
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .phone{
    margin-right: 20px;
  }
  .contact-item{
    display: inline-block;
    float: right;
    margin-top: 35px;
    span{
      margin: 0 10px;

    }
    a{
      padding: 5px 30px;
      background-color:$dark-blue;
      color: #fff;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .search-wrapper{
    display: inline-block;
    float: none;
    clear: both;
    margin-top: 15px;
    .input-group-addon{
      background-color: $dark-purple;
      padding: 4px 35px;
      border: 0;
      border-radius: 0;
      color: #fff;
      font-size:14px;
    }
  }
}
.navbar{
  background-color: $dark-blue;
  min-height: 1px;
  margin-bottom: 0;
  border-radius: 0;
  border: 0;
  .navbar-collapse{
    padding: 0;
    .navbar-nav{
      float: none;
      text-align: center;
      li{
        display: inline-block;
        float: none;
        a{
          background-color: transparent;
          color: #fff;
          padding: 0 108px;
          border-left: 1px solid #fff;
          margin: 10px 0;
          text-transform: uppercase;
        }
        &:last-child{
          a{
            border-right: 1px solid #fff;
          }
        }
      }

      .active{
        a{
          background-color: $dark-purple;
          border: none!important;
          padding-top: 10px;
          padding-bottom: 10px;
          margin: 0;
          font-weight: bold;
        }
        &+li{
          a{
            border-left: 0!important;
          }
        }
      }
    }
  }
}
@media(max-width: 1200px){
  .navbar {
    .navbar-collapse {
    .navbar-nav {
        li {
          a {
            padding: 0 83px;
          }
        }
      }
    }
  }
}
@media(max-width: 993px){
  .navbar {
    .navbar-collapse {
    .navbar-nav {
        li {
          a {
            padding: 0 55px;
          }
        }
      }
    }
  }
}
@media(max-width: 767px){
  header{
    .contact-item{
      display: block;
      clear: both;
      float: none;
      text-align: center;
      margin-top: 15px;
      margin-left: 0;
      margin-right: 0;
      span{
        display: block;
        float: none;
        clear: both;
      }
    }
    .logo-div{
      margin-bottom: 15px;
    }
    .search-wrapper{
      margin-bottom: 10px;
    }
  }
  .navbar {
    .navbar-collapse {
      margin: 0;
      .navbar-nav {
        margin-left: 0;
        margin-right: 0;
        li {
          display: block;
          float: none;
          width: 100%;
          text-align: center;
          a {
            padding: 10px 0;
            border: 0!important;
          }
        }
      }
    }
  }
  .navbar-toggle{
    background-color: $dark-purple;
    border: 0;
    border-radius: 0;
    .icon-bar{
      background-color: #fff;
    }
  }
}